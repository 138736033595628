import React from 'react';

type ProfileImagePlaceholderProps = {
  username: string;
  containerClassName?: string;
};

const ProfileImagePlaceholder: React.FC<ProfileImagePlaceholderProps> = ({
  username,
  containerClassName,
}) => {
  return (
    <div
      className={`w-9 h-9 flex items-center justify-center bg-light-green rounded-full ${containerClassName}`}
    >
      <p className="m-0 p-0 text-xl font-medium text-primary">
        {username?.charAt(0).toUpperCase()}
      </p>
    </div>
  );
};

export default ProfileImagePlaceholder;

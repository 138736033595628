import { User } from '../user/user.models';

export const AVAILABLE_NOTIFICATION_TIMES = [
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
] as const;

export enum VotingDay {
  Monday = 'MON',
  Tuesday = 'TUE',
  Wednesday = 'WED',
  Thursday = 'THU',
  Friday = 'FRI',
  Saturday = 'SAT',
  Sunday = 'SUN',
}

export enum TeamStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

export interface TeamUser extends User {
  votingDays: VotingDay[];
  notificationTime: string;
  notificationEnabled: boolean;
  useDefaultVotingDays: boolean;
}

export interface Team {
  id: string;
  name: string;
  admins: string[];
  membersCount: number;
  companyId: string;
  voteAnonymous: boolean;
  status: TeamStatus;
  votingDays: VotingDay[];
  notificationTime: string;
}

export interface ResponseTeam {
  id: string;
  name: string;
  admins: string[];
  companyId: string;
  membersCount: number;
  status: TeamStatus;
  statusFilter: string;
  voteAnonymous: boolean;
  votingDays: VotingDay[];
  notificationTime: string;
}

export interface GetCompanyTeamsRequest {
  companyId: string;
  query?: string;
  status?: TeamStatus | null;
  next?: string;
  prev?: string;
}

export interface GetCompanyTeamsResponse {
  items: ResponseTeam[];
  next: string | null;
  previous: string | null;
}

export interface CreateTeamRequest {
  name: string;
  companyId: string;
  voteAnonymous: boolean;
  votingDays: VotingDay[];
}

export interface CreateTeamResponse {
  item: Team;
}

export interface UpdateTeamResponse {
  item: Team;
}

export interface UpdateTeamRequest {
  teamId: string;
  name?: string;
  voteAnonymous?: boolean;
  votingDays?: VotingDay[];
  notificationTime?: string;
}

export interface UpdateTeamStatusRequest {
  teamId: string;
  status: TeamStatus;
}

export interface AddMemberRequest {
  teamId: string;
  memberId: string;
}

export interface AddMemberResponse {
  item: TeamUser;
}

export interface GetTeamMembersRequest {
  teamId: string;
  next?: string;
  prev?: string;
}

export interface GetTeamMembersResponse {
  items: TeamUser[];
  next: string | null;
  previous: string | null;
}

export interface RemoveTeamMemberRequest {
  teamId: string;
  memberId: string;
}

export interface GetCompanyTeamDetailsRequest {
  teamId: string;
}

export interface GetUserTeamsRequest {
  userId: string;
}

export interface SetTeamMemberAsAdminRequest {
  teamId: string;
  memberId: string;
}

export interface UpdateTeamMemberMetaRequest {
  teamId: string;
  memberId: string;
  workingDays?: VotingDay[];
  notificationTime?: string;
  notificationEnabled?: boolean;
  useDefaultVotingDays?: boolean;
}

export interface SetTeamMemberAsUserRequest {
  teamId: string;
  memberId: string;
}

export interface GetCompanyTeamDetailsResponse {
  item: Team;
}

export interface GetTeamsForUserResponse {
  items: Team[];
}

export interface GetTeamsForUserRequest {
  userId: string;
  admin?: 1 | 0;
  next?: string;
  prev?: string;
}

export interface GetTeamDetailsRequest {
  teamId: string;
}

export interface GetTeamDetailsResponse {
  item: Team;
}

export interface GetUserTeamListRequest {
  userId: string;
}

export interface GetUserTeamListResponse {
  items: Team[];
}
